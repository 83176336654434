<template>
  <b-card title="Data Gaji Tetap">
    <div class="custom-search">
      <b-row>
        <b-col cols="12" md="4">
          <div class="float-left">
            <b-form-group>
              <b-form-input placeholder="Cari" type="text" class="d-inline-block" @input="advanceSearch" />
            </b-form-group>
          </div>

        </b-col>
        <b-col cols="12" md="8">
          <div class="float-right">
            <b-button variant="gradient-primary" class="btn-icon" @click="AddData" size="sm">
              <feather-icon icon="UserPlusIcon" /> Tambah
            </b-button>
            <b-button variant="gradient-success" class="btn-icon" @click="exportToExcel" size="sm">
              <feather-icon icon="DownloadCloudIcon" /> Export
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="custom-table">
      <!-- table -->
      <vue-good-table :columns="columns" :rows="rows" styleClass="vgt-table striped" :line-numbers="true" :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}" theme="black-rhino">
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <span>
              <b-button-group>
                <b-button size="sm" variant="gradient-info" class="btn-icon" @click="EditData(props.row.id)" title="Ubah">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button size="sm" variant="gradient-danger" class="btn-icon" @click="riseUpModalRemove(props.row.id)"
                  title="Hapus">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-button-group>
            </span>
          </span>
          <!-- <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span> -->
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="pageLength" :options="['10', '50', '100']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })
                " />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <!-- <Table /> -->
    </div>
    <!-- <template #code>
      {{ codeAdvance }}
    </template> -->
  </b-card>
</template>

<style>
.custom-table td {
  font-size: 14px;
  /* Adjust the value as per your requirement */
}
</style>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import Table from '@/views/table/vue-good-table/GoodTable.vue'
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/no-unresolved
import { codeAdvance } from './code'
import * as XLSX from 'xlsx';

export default {
  components: {
    // BCardCode,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'NIP',
          field: 'employee.data.nip',
        },
        {
          label: 'Nama Lengkap',
          field: 'employee.data.name',
        },
        {
          label: 'Tanggal Efektif',
          field: 'effective_date',
          formatFn: this.formattedDate,
        },
        {
          label: 'Aktif',
          field: 'employee.data.statuskaryawan',
        },
        {
          label: 'Jabatan',
          field: 'employee.data.jabatan.name',
        },
        {
          label: 'Nama Komponen',
          field: 'component_name',
        },
        {
          label: 'Nilai (RP)',
          field: 'nominal',
          formatFn: this.formatFn,
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAllEmployee()
  },
  methods: {
    exportToExcel() {

      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(this.rows);

      // Create a new workbook
      const wb = XLSX.utils.book_new(); // Use XLSX.utils.book_new() here
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate an Excel file and trigger download
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, 'datapegawai.xlsx');
    },

    saveExcelFile(buffer, fileName) {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // For IE browser
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        // For modern browsers
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    },
    formattedDate(sampleDate) {
      const date = new Date(sampleDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatFn: function (value) {
      var usFormat = value.toLocaleString('en-US')
      return 'Rp. ' + usFormat;
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Employee.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    AddData() {
      this.$router.push({ name: 'gaji-tetap-add' })
    },
    EditData(val) {
      this.$router.push({ name: 'gaji-tetap-edit', params: { id: val } })
    },
    async getAllEmployee() {
      try {
        const response = await this.$http.get('/fixedsalary/recap')
        this.rows = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },
    async removeParent(val) {
      try {
        console.log(val)
        await this.$http.delete(`/fixedsalary/recap/${val}`)
        await this.getAllEmployee()
        this.showToast('success', 'top-center', 'Delete Data Success')
      } catch (err) {
        this.showToast('danger', 'top-center', 'Delete Data Not Success')
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
